import { template as template_ec766959122b4dd185ededd4c75d64bd } from "@ember/template-compiler";
const FKControlMenuContainer = template_ec766959122b4dd185ededd4c75d64bd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
