import { template as template_0f233671abe94439afd44bb633760b65 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0f233671abe94439afd44bb633760b65(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
