import { template as template_164c1f413dbb4ee3927b2f406f754796 } from "@ember/template-compiler";
const FKLabel = template_164c1f413dbb4ee3927b2f406f754796(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
